import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="5 Top Secrets Of The Most Photogenic Smiles | The Smile Stylist" 
    description="Want a Hollywood smile? The Smile Stylist Cosmetic Dentistry clinic will help you to gain a photogenic and natural looking smile using ultra-thin porcelain veneers." 
    keywords="better smile, best smile, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Five Top Secrets of the Most Photogenic Smiles</h1>
              <h2>Are you doing these things before the camera clicks?</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight animated bl__ani">
            <p className="lead">They say the best smiles are genuine. (And straight and white, of course)!</p>
            <p>With that said, have you been putting your greatest- and realest- smile forward in photos? Something to think about.</p>
            <p>There are actually several ways you can ensure your smile is always photo-ready, and as photogenic as can be! Let’s talk about five top ways you can help to ensure you flash your most confident, beautiful and natural smile every time the flash goes off:</p>
            <h3>1. Find your golden angle.</h3>
            <p>You know your most flattering angles better than anyone else! Play up your smile in the mirror and get a feel for what works best. In addition to this, it won’t hurt to take a look at some photos of yourself to try and observe which angles or poses suit you and your smile best.</p>
            <p>If your natural smile has a notable squinting effect on your eyes, it may help to widen your eyes ever so slightly so that they don’t disappear. You also want to ensure that your smile feels comfortable, and not forced.</p>
            <p>As far as head positioning goes, almost everyone looks best with their chin positioned slightly downward, as it tends to have a slimming effect on the face. Try turning your head horizontally as well to a light angle to show dimension.</p>
            <h3>2. Think happy thoughts before the camera clicks.</h3>
            <p>Smiles that appear forced or non-genuine are perceived as less inviting and attractive (and we can all tell a forced smile from a real one – the giveaway is in the eyes!). If you think happy thoughts to incite good feelings just before the camera goes off, your smile will appear far more natural and real.</p>
            <h3>3. Whiten before your photo op.</h3>
            <p>Not all lighting is forgiving of a yellow or dull smile. A professional
              <a href="/teeth-whitening">whitening treatment</a> with our Smile Stylist Dr. Kaloti will ensure you reveal your best and brightest smile for your photo and for months to come.
            </p>
            <h3>4. Smile before the snapshot.</h3>
            <p>If you hold an early smile the photo is taken, this will work in your favor. This gives your face the chance to establish a great smile and genuine emotion before it’s captured. Give it a whirl!</p>
            <h3>5. Skip the “cheese” (PLEASE)!</h3>
            <p>Cheese is a very beneficial food for your dental health, but when it comes to taking a photo, you’re far better off skipping its pronunciation. Don’t worry about being an outcast here – it’s for your own good. (And we’re willing to bet you won’t be the only one denying the “cheese”)!</p>
            <p>Will you be trying these 5 top secrets to a photogenic and genuine smile the next time you smile for a photograph? Let us know how it works out for you!</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Golden Angle" src={ require("../../images/desktop/_blog/pics/ftop1.jpg" )} className="img-responsive wow animated zoomIn animated bl__ani" data-wow-delay="0.4s"/><br /> 
            <img alt="Think Happy Thoughts" src={ require("../../images/desktop/_blog/pics/ftop2.jpg" )} className="img-responsive wow animated zoomIn animated bl__ani" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage